import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';
import { ActivationStart, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../store/app.reducers';
import { Order } from '@app/models/order/order';
import { fromEvent, Observable, Subscription, BehaviorSubject } from 'rxjs';
import * as CheckoutActions from '../../../../ui/user/checkout/store/checkout.actions';
import { Links } from '@app/models/links';
import { filter } from 'rxjs/operators';
import { OidcClientNotification, OidcSecurityService, PublicConfiguration } from 'angular-auth-oidc-client';

import { Identity, IdentityService } from '@app/identity.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from '@app/auth.service';
import { FeatureService } from '@app/feature.service';

@Component({
  selector: 'app-user-topbar',
  templateUrl: './user-topbar.component.html',
  styleUrls: ['./user-topbar.component.scss'],
  animations: [
    trigger('fadeState', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(300),
      ]),
      transition(
        ':leave',
        animate(
          300,
          style({
            opacity: 0,
          })
        )
      ),
    ]),
  ],
})
export class UserTopbarComponent implements OnInit, OnDestroy {
  @Input() scrolling: boolean;
  @Input() notifications: Notification[];
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  @Output() displayHamburger = new EventEmitter<any>();
  @Input() shoppingCart;
  @Input() showPanel: boolean;
  order: Order = new Order();
  location: Location;

  private role: string;
  cirrusUserName: string;
  sub: Subscription = new Subscription();
  isMobile = false;
  total = 0.0;
  showNavbar = false;
  navOpen = false;
  barState = 'transparent';

  learnToFlyLink: string = this.isLoggedIn() ? Links.LoggedInFlyLink : Links.loggedOutFlyLink;
  flightFixLink: string = this.isLoggedIn() ? Links.LoggedInFixLink : Links.LoggedOutFixLink;

  showCTCDashboard = false;
  showInstructorDashboard = false;
  showAdminDashboard = false;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  @ViewChild('appMenuTrigger') appMenuTrigger: MatMenuTrigger;

  constructor(
    private authService: AuthService,
    private globalEventManagerService: GlobalEventManagerService,
    private router: Router,
    private store$: Store<fromApp.AppState>,
    route: ActivatedRoute,
    private identityService: IdentityService,
    private featureService: FeatureService
  ) {
    const { showNavbar } = route.firstChild.snapshot.data;
    this.showNavbar = showNavbar;
    router.events.pipe(filter(event => event instanceof ActivationStart)).subscribe((event: ActivationStart) => {
      const { showNavbar } = event.snapshot.data;
      this.showNavbar = showNavbar;
    });
  }

  ngOnInit() {
    this.checkIfMobile();
    const cirrusUser = JSON.parse(localStorage.getItem('cirrus-user'));
    this.cirrusUserName = cirrusUser?.name;
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.checkIfMobile();
    });

    const identitySubscription = this.identityService.identity.subscribe(identity => {
      this.showCTCDashboard = identity.ctcAdmin;
      this.showAdminDashboard = ['admin', 'super_admin'].includes(identity.role);
      this.showInstructorDashboard = identity.role === 'instructor' || this.showAdminDashboard;
      if (identity.user) {
        const user = this.produceCirrusUser(identity.user as string);
        this.cirrusUserName = user.name;
      }
    });

    this.sub.add(identitySubscription);

    this.store$.select('checkout').subscribe(state => {
      this.order = state.order;
      this.total = 0;
      state.order.order_line_items.forEach(lineItem => {
        if (lineItem.product && lineItem.product.list_price) {
          this.total += parseFloat(lineItem.product.list_price.toString());
        }
      });
      if (state.order) {
        this.order = state.order;
      }
    });
  }

  private produceCirrusUser(userString: string = '{}') {
    return JSON.parse(userString);
  }

  onResize() {
    this.checkIfMobile();
  }

  isLoggedIn() {
    return localStorage.getItem('cirrus-token') != null;
  }

  getRole() {
    return localStorage.getItem('cirrus-role');
  }

  getImpersonation() {
    if (localStorage.getItem('cirrus-impersonation-return') != null) {
      if (localStorage.getItem('cirrus-impersonation-return') === 'null') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  logoutOfImpersonation() {
    localStorage.setItem('cirrus-token', localStorage.getItem('cirrus-impersonation-return'));
    localStorage.setItem('cirrus-user', localStorage.getItem('cirrus-impersonation-return-user'));
    localStorage.setItem('cirrus-role', localStorage.getItem('cirrus-impersonation-return-role'));

    localStorage.setItem('cirrus-impersonation-return-role', null);
    localStorage.setItem('cirrus-impersonation-return', null);
    localStorage.setItem('cirrus-impersonation-return-user', null);

    this.identityService.endImpersonation();

    this.router.navigate(['admin/dashboard']);
  }

  goToCart() {
    this.router.navigate(['/shopping-cart']);
  }

  removeItem(item) {
    this.store$.dispatch(new CheckoutActions.RemoveFromCart(item));
  }

  checkLocation() {
    return ['/shopping-cart', '/checkout', '/recent-purchase'].indexOf(this.router.url) === -1;
  }

  toggleShoppingCart(el) {
    this.shoppingCart.toggle(el);
  }

  toggleMenu() {
    this.displayHamburger.emit(!this.showPanel);

    if (this.appMenuTrigger.menuOpen) {
      this.appMenuTrigger.closeMenu();
    }
  }

  closeHamburgerMenu() {
    this.displayHamburger.emit(false);
  }

  logout() {
    this.router.navigate(['/sign-out']);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  hrefSfProfile() {
    try {
      return environment.profile;
    } catch (x) { }
  }

  login() {
    this.router.navigate(['/sign-in']);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.resizeSubscription$.unsubscribe();
  }

  checkIfMobile() {
    if (window.matchMedia('(max-width: 599px)').matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
