<mat-toolbar class="header" fxLayoutAlign="center center">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <a href="#" class="logo">
      <img
        class="logo smallscreen-logo"
        src="assets/images/cirrus-approach-logo.svg"
        alt=""
        fxFlex.xs="100"
        fxFlex.sm="100"
        fxFlex.md="100"
        fxFlex.lg="0"
        fxFlex.xl="0"
      />
    </a>
    <div fxLayout="row" fxLayoutAlign="center center">
      <a
        routerLink="/shopping-cart"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="!isLoggedIn()"
        class="shopping-cart-link"
      >
        <img src="assets/images/cart.svg" alt="Shopping Cart Icon" />
        <app-badge *ngIf="order?.order_line_items?.length"> {{ order?.order_line_items?.length }}</app-badge>
      </a>
      <button fxFlexOrder.xs="2" *ngIf="!isLoggedIn()" (click)="login()" class="icon-spacing login-button">
        Sign In
      </button>
      <div *ngIf="isLoggedIn()" class="cart-menu" fxFlexOrder.xs="1">
        <a routerLink="/shopping-cart" fxLayout="row" fxLayoutAlign="center center">
          <img src="assets/images/cart.svg" alt="Notifications Icon" />
          <app-badge *ngIf="order?.order_line_items?.length">{{ order?.order_line_items?.length }}</app-badge>
        </a>
      </div>
      <div fxFlexOrder.xs="2" id="account_wrapper" class="icon-spacing">
        <button
          *ngIf="isLoggedIn()"
          id="account_icon"
          class="icon-spacing"
          mat-icon-button
          disableRipple
          [matMenuTriggerFor]="appMenu"
          #appMenuTrigger="matMenuTrigger"
          (menuOpened)="closeHamburgerMenu()"
        >
          <div fxLayout="row" fxLayoutGap.gt-sm="12px" fxLayoutGap.lt-md="6px" fxLayoutAlign="center center">
            <div class="profile-icon logged-in">
              <!-- Add user name, convert name to initials -->
              {{ cirrusUserName | nameToInitials }}
            </div>
            <ng-container *ngIf="!isMobile && isLoggedIn()">
              <div fxLayout="column">
                <span class="welcome-back">Welcome Back,</span>
                <div fxLayout="row" fxLayoutGap="12px">
                  <span class="user-name">{{ cirrusUserName }}</span>
                </div>
              </div>
            </ng-container>
            <img class="chevron" src="assets/images/down-chevron.svg" height="8.63px" width="24px" />
          </div>
        </button>
        <mat-menu #appMenu="matMenu" [hasBackdrop]="false">
          <ng-container>
            <button mat-menu-item *ngIf="showCTCDashboard" routerLink="ctc-admin/dashboard">CTC Dashboard</button>
          </ng-container>
          <button mat-menu-item *ngIf="showInstructorDashboard" routerLink="instructor/dashboard">
            Instructor Dashboard
          </button>

          <button mat-menu-item *ngIf="showAdminDashboard" routerLink="admin/dashboard">Admin Dashboard</button>
          <a
            mat-menu-item
            href="https://cirrusaircraft.com?app_name=Salesforce&option=oauthredirect&redirect_url=https%3A%2f%2fcirrusaircraft.com%2faccount%2fflight-deck"
            >Flight Deck</a
          >
          <a mat-menu-item *ngIf="hrefSfProfile()" [href]="hrefSfProfile()">Edit Profile</a>
          <button mat-menu-item routerLink="purchase-history">Purchase History</button>
          <!-- <button mat-menu-item routerLink='home'>My Learning</button> -->
          <mat-divider></mat-divider>
          <a mat-menu-item href="https://www.cirrusapproach.com/learn-to-fly/" target="_blank">
            Learn to Fly
            <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.78883 1H1V20.6122H20.6122V13.8233"
                stroke="#C4C4C4"
                stroke-width="0.92"
                stroke-linejoin="round"
              />
              <path
                d="M21.0716 1C21.0716 0.745949 20.8656 0.54 20.6116 0.54H16.4716C16.2175 0.54 16.0116 0.745949 16.0116 1C16.0116 1.25405 16.2175 1.46 16.4716 1.46H20.1516V5.14C20.1516 5.39405 20.3575 5.6 20.6116 5.6C20.8656 5.6 21.0716 5.39405 21.0716 5.14V1ZM9.62214 12.64L20.9369 1.32527L20.2863 0.674731L8.97161 11.9894L9.62214 12.64Z"
                fill="#C4C4C4"
              />
            </svg>
          </a>
          <a
            mat-menu-item
            href="https://www.youtube.com/playlist?list=PLEZkne-402vDOF39xpPCqDttnRfm90QuZ"
            target="_blank"
          >
            Flight Fix
            <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.78883 1H1V20.6122H20.6122V13.8233"
                stroke="#C4C4C4"
                stroke-width="0.92"
                stroke-linejoin="round"
              />
              <path
                d="M21.0716 1C21.0716 0.745949 20.8656 0.54 20.6116 0.54H16.4716C16.2175 0.54 16.0116 0.745949 16.0116 1C16.0116 1.25405 16.2175 1.46 16.4716 1.46H20.1516V5.14C20.1516 5.39405 20.3575 5.6 20.6116 5.6C20.8656 5.6 21.0716 5.39405 21.0716 5.14V1ZM9.62214 12.64L20.9369 1.32527L20.2863 0.674731L8.97161 11.9894L9.62214 12.64Z"
                fill="#C4C4C4"
              />
            </svg>
          </a>
          <mat-divider></mat-divider>
          <button mat-menu-item *ngIf="getImpersonation()" (click)="logoutOfImpersonation()">
            Impersonation Logout
          </button>
          <button mat-menu-item *ngIf="!getImpersonation()" (click)="logout()">Log Out</button>
        </mat-menu>
      </div>
      <button
        fxShow
        fxHide.gt-xs="true"
        fxFlexOrder.xs="3"
        (click)="toggleMenu()"
        (emitDismissHamburgerMenu)="toggleMenu()"
        class="icon-spacing hamburger-icon"
        mat-icon-button
      >
        <div *ngIf="notifications.length && !showPanel" class="notifications-dot"></div>

        <img *ngIf="!showPanel" src="assets/images/hamburger.svg" alt="Mobile Nav Icon" />
        <img *ngIf="showPanel" src="assets/images/close.svg" alt="Mobile Close Icon" />
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
